/* eslint-disable no-undef */
import {Utils} from '@busuu/legacy-core';
import AuthenticationService from 'authentication/authentication-service.js';
import CaptchaService from 'captcha/captcha-service.js';
import TranslationsService from 'common/translations.service.ts';
import FormUtils from 'helpers/form-utils';
import ReferralService from 'referral/referral-service';
import {AuthenticationAppleConstant} from './authentication-constants';

let errorElement;
let AppleButton;
const disabledClass = 'social-auth--disabled';

const appleConfigObject = {
    clientId: AuthenticationAppleConstant.clientId,
    scope: AuthenticationAppleConstant.scope,
    redirectURI: AuthenticationAppleConstant.redirectURI,
    state: AuthenticationAppleConstant.state,
    usePopup: AuthenticationAppleConstant.usePopup,
};

/**
 * Error Tracking sent after APPLE API
 * fail or Busuu API fail
 */
const sendTrackingError = (type) => {
    AuthenticationService.sendLoginErrorTracking('APPLE', {error: type});
};

/**
 * Returns the error string for an user already registered
 * @return {String}
 */
const getUserAlreadyRegisteredMessage = () => {
    let message = '';
    const dataUrl = errorElement.getAttribute('data-login-url');
    message += TranslationsService.getTranslation('USER_ALREADY_EXISTS');
    if (dataUrl) {
        message += `<br><a href=${dataUrl}>${TranslationsService.getTranslation('LOGIN')}</a>`;
    }
    return message;
};

/**
 * Returns the error string for an user that tries to login
 * with a not existing account
 * @return {String}
 */
const getUserNotExistingMessage = () => {
    let message = '';
    const dataUrl = errorElement.getAttribute('data-register-url');
    message += TranslationsService.getTranslation('USER_DOESNT_EXIST');
    if (dataUrl) {
        message += `<br><a href=${dataUrl}>${TranslationsService.getTranslation('REGISTER')}</a>`;
    }
    return message;
};

/**
 * Get the error message to display based on the type of the error
 * @return {String}
 */
const getErrorTextMessage = (type) => {
    switch (type) {
        case 'USER_REGISTERED':
            return getUserAlreadyRegisteredMessage();
        case 'USER_NOT_REGISTERED':
            return getUserNotExistingMessage();
        default:
            return `${TranslationsService.getTranslation('ERROR')} - ${TranslationsService.getTranslation(
                'TRY_AGAIN'
            )}`;
    }
};

/**
 * Hide/Show Social Error
 */
const showError = (type, status) => {
    const code = status ? `[${status} - ${type}]` : `[${type}]`;
    const message = `${getErrorTextMessage(type)}<br><small>${code} - APPLE</small>`;
    errorElement.innerHTML = message;
    FormUtils.showElement(errorElement);
    window.scrollTo(0, 0);
};

const handleError = ({type = 'UNKNOWN_TYPE', status = null}) => {
    const ignoreError = ['APPLE_POPUP_CLOSED'].includes(type);
    sendTrackingError(type);
    if (!ignoreError) {
        showError(type, status);
    }
};

const redirect = (url) => {
    window.location.href = url;
};

const onAuthSuccess = (response) => {
    if (response.backendRedirection) {
        return redirect(response.backendRedirection);
    }
    return AuthenticationService.sendLoginSuccessTracking('APPLE').then(() => {
        const url = AppleButton.getAttribute('data-redirect');
        redirect(url);
    });
};

const authUser = (appleJWTToken, captchaToken) => {
    const params = {token: appleJWTToken};
    const perkToken = ReferralService.getPerkToken();
    if (captchaToken) {
        params.captcha_token = captchaToken;
    }

    if (perkToken) {
        params.perk_token = perkToken;
    }
    return AuthenticationService.authAppleUserOnBusuu(params).then(onAuthSuccess);
};

const initCaptcha = (appleJWTToken) => {
    const onCaptchaInit = (result) => {
        return authUser(appleJWTToken, result.token);
    };
    CaptchaService.initializeAndRenderChallenge({
        endpointType: 'login_vendor',
        containerID: 'login-recaptcha',
    })
        .then(onCaptchaInit)
        .catch(handleError);
};

const initAppleAuth = () => {
    if (!window.AppleID) {
        return;
    }

    AppleButton = Utils.getById('appleid-signin-button');
    errorElement = Utils.getById('error-generic');

    Utils.removeClass(AppleButton, disabledClass);

    AppleButton.addEventListener('click', async function () {
        FormUtils.hideElement(errorElement);
        try {
            const data = await AppleID.auth.signIn();
            // Handle successful response.
            const {id_token: appleJWTToken, state} = data.authorization;
            if (state === AuthenticationAppleConstant.state) {
                initCaptcha(appleJWTToken);
            }
        } catch (error) {
            // Handle error.
            handleError({type: error.error});
        }
    });

    window.AppleID.auth.init(appleConfigObject);
};

export {initAppleAuth};
