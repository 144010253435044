import {Utils, ScriptLoader, ThirdPartyService} from '@busuu/legacy-core';
import {initAppleAuth} from '../authentication/authentication-apple.js';
import {getLocale} from '../authentication/authentication-constants.js';
import PubSub from 'pubsub-js/src/pubsub.js';

/**
 * Apple Login
 */
(function (root, doc, undefined) {
    const isVendorDisabled = function () {
        return ThirdPartyService.isVendorDisabled();
    };

    const onAppleAuthLoaded = function () {
        PubSub.publish('APPLE_API_LOADED');
        initAppleAuth();
    };

    const init = () => {
        if (isVendorDisabled()) {
            return false;
        }

        const url = '//appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/' + getLocale() + '/appleid.auth.js';

        ScriptLoader.add(url, 'applelogin', onAppleAuthLoaded);
    };

    init();
})(window, document);
