import {Utils, CountriesService as CoreCountriesService} from '@busuu/legacy-core';
import FormUtils from 'helpers/form-utils.js';
import TranslationsService from 'common/translations.service.ts';
import MaskedSelect from 'components/forms/masked-select-class.js';
import CountriesService from 'common/countries-service.js';

const SWITCHER_CLASS_GROUP_EMAIL = 'js-field-switch__email';
const SWITCHER_CLASS_GROUP_MOBILE = 'js-field-switch__mobile';
const SWITCHER_CLASS_SELECT_WRAP = 'js-field-switch__select-wrap';
const SWITCHER_CLASS_SELECT = 'js-field-switch__select';
const SWITCHER_CLASS_INPUT = 'js-field-switch__input';
const SWITCHER_CLASS_BUTTON = 'js-field-switch__link';

export const FIELD_SWITCH_TYPE_EMAIL = 'email';
export const FIELD_SWITCH_TYPE_MOBILE = 'mobile';

/**
 * A form element that can be switched between email + mobile values
 *
 * @example
 * const instance = new MaskedSelect(element, options);
 *
 * <div class="js-field-switch">
 *   <div class="js-field-switch__email form-group js-formGroup">
 *      <label class="form__label" />
 *      <input class="js-field-switch__input form__input" />
 *   </div>
 *   <div class="js-field-switch__mobile form-group js-formGroup hidden">
 *     <label class="form__label" />
 *     <div class="field-switch__field">
 *       <div class="field-switch__field-item field-switch__field-item--s">
 *         <div class="js-field-switch__select-wrap js-masked-select form__select-wrap">
 *           <select class="js-field-switch__select js-masked-select__select form__select" />
 *           <span class="js-masked-select__mask form__select-mask" />
 *         </div>
 *     </div>
 *     <div class="field-switch__field-item">
 *       <input class="js-field-switch__input form__input" />
 *     </div>
 *   </div>
 *   <a href="#" class="js-field-switch__link" />
 * </div>
 */
class EmailMobileSwitcher {
    /**
     * Create and initialize an email / mobile field switcher
     * @param {Element} wrapperElement
     * @param {Object} options
     * @param {String} options.initialSelectValue
     * @param {'email'|'mobile'} options.initialState
     * @param {Boolean} options.disableStateSwitch - if the field can switch state
     * @param {Function} options.onStateChange - called when the field switches state
     * @param {Function} options.onChangeCallingCode - called when mobile calling code changes
     */
    constructor(wrapperElement, options) {
        this.options = options;
        this.currentState = options.initialState || FIELD_SWITCH_TYPE_EMAIL;

        this.wrapperElement = wrapperElement;
        this.buttonSwitchElement = null;
        this.emailGroupElement = null;
        this.mobileGroupElement = null;
        this.emailElement = null;
        this.mobileElement = null;
        this.mobileCountryCodeElement = null;

        this.getElements();
        this.initMobileFormGroup();
        this.initSwitcher();
    }

    getElements() {
        this.buttonSwitchElement = Utils.getElementByClass(SWITCHER_CLASS_BUTTON, this.wrapperElement);
        this.emailGroupElement = Utils.getElementByClass(SWITCHER_CLASS_GROUP_EMAIL, this.wrapperElement);
        this.mobileGroupElement = Utils.getElementByClass(SWITCHER_CLASS_GROUP_MOBILE, this.wrapperElement);
        this.emailElement = Utils.getElementByClass(SWITCHER_CLASS_INPUT, this.emailGroupElement);
        this.mobileElement = Utils.getElementByClass(SWITCHER_CLASS_INPUT, this.mobileGroupElement);
        this.mobileCountryCodeElement = Utils.getElementByClass(SWITCHER_CLASS_SELECT, this.mobileGroupElement);
    }

    initMobileFormGroup() {
        const {initialSelectValue, onChangeCallingCode} = this.options;

        const selectWrapper = Utils.getElementByClass(SWITCHER_CLASS_SELECT_WRAP, this.mobileGroupElement);
        const countries = CoreCountriesService.getCountryList();
        const countryCodeValues = CountriesService.getCountryCodeListForSelect(countries);

        new MaskedSelect(selectWrapper, {
            initialValue: initialSelectValue,
            values: countryCodeValues,
            onChange: onChangeCallingCode,
        });
    }

    switchCurrentField(nextState) {
        const {onStateChange} = this.options;

        const showMobileField = nextState === FIELD_SWITCH_TYPE_MOBILE;
        const switchTrsKey = showMobileField ? 'USE_YOUR_EMAIL' : 'USE_YOUR_MOBILE_NUMBER';

        this.currentState = nextState;

        FormUtils.toggleElement(this.mobileGroupElement, showMobileField);
        FormUtils.toggleRequireElement(this.mobileElement, showMobileField);
        FormUtils.toggleRequireElement(this.mobileCountryCodeElement, showMobileField);

        FormUtils.toggleElement(this.emailGroupElement, !showMobileField);
        FormUtils.toggleRequireElement(this.emailElement, !showMobileField);

        Utils.setText(this.buttonSwitchElement, TranslationsService.getTranslation(switchTrsKey));

        if (onStateChange) {
            onStateChange(nextState);
        }
    }

    onSwitchButtonClick(e) {
        if (e) {
            e.preventDefault();
        }

        const nextState =
            this.currentState === FIELD_SWITCH_TYPE_EMAIL ? FIELD_SWITCH_TYPE_MOBILE : FIELD_SWITCH_TYPE_EMAIL;

        this.switchCurrentField(nextState);
    }

    initSwitcher() {
        const {disableStateSwitch} = this.options;

        if (disableStateSwitch) {
            FormUtils.hideElement(this.buttonSwitchElement);
        } else {
            this.buttonSwitchElement.addEventListener('click', this.onSwitchButtonClick.bind(this));
        }

        if (this.currentState === FIELD_SWITCH_TYPE_MOBILE) {
            this.switchCurrentField(FIELD_SWITCH_TYPE_MOBILE);
        }
    }

    /**
     * Gets the value of the current field
     * @returns {String}
     * @public
     */
    value() {
        if (this.currentState === FIELD_SWITCH_TYPE_MOBILE) {
            const countryCode = this.mobileCountryCodeElement.value;
            const mobile = this.mobileElement.value;

            return `${countryCode}${mobile}`;
        }

        return this.emailElement.value;
    }

    /**
     * Focus the current field
     * 1. Ensures the cursor is always positioned at the end
     * @public
     */
    focus() {
        const elementToFocus =
            this.currentState === FIELD_SWITCH_TYPE_MOBILE ? this.mobileCountryCodeElement : this.emailElement;

        const val = this.value();
        elementToFocus.value = ''; // 1
        elementToFocus.value = val; // 1
        FormUtils.focusElement(elementToFocus, true);
    }

    /**
     * Get the current field state
     * @returns {'email'|'mobile'}
     * @public
     */
    getState() {
        return this.currentState;
    }
}

export default EmailMobileSwitcher;
