import {CountriesService as CoreCountriesService} from '@busuu/legacy-core';
import TranslationsService from 'common/translations.service.ts';

/**
 * Sorts and returns a countries object ready for a calling code select based off
 * interface lang
 * @param {Object} countries
 * @returns {Array} countries list
 */
const getCountryCodeListForSelect = (countries) => {
    const mappedCountries = Object.values(countries).map((country) => ({
        ...country,
        label: TranslationsService.getTranslation(`COUNTRY_${country.code}`),
    }));

    const sortedCountries = CoreCountriesService.sortByLabel(mappedCountries, window.BUSUU_GLOBALS.LANG_INTERFACE).map(
        ({code, label, callingCode}) => {
            const countryValue = `+${callingCode}`;
            const countryLabel = `${label} (${countryValue})`;

            return {
                code,
                value: countryValue,
                label: countryLabel,
                labelMask: countryValue,
            };
        }
    );

    return sortedCountries;
};

const CountriesService = {
    getCountryCodeListForSelect,
};

export default CountriesService;
