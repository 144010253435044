import {Utils} from '@busuu/legacy-core';
import DeviceDetection from 'helpers/device-detection.js';

const ACCOUNT_CLASS = 'auth-form__flyover-wrap--active';
const ACCOUNT_INNER_CLASS = 'auth-form__flyover--active';
const NO_SCROLL_CLASS = 'no-scroll--mq-s';
const NO_SCROLL_IOS_CLASS = 'no-scroll--mq-s-ios';

class AccountFlyover {
    constructor() {
        this.accountElement = null;
        this.accountInnerEl = null;
        this.accountReject = null;

        this.shouldOverlayClose = this.shouldOverlayClose.bind(this);
        this.hide = this.hide.bind(this);

        this.initFlyover();
    }

    initFlyover() {
        this.accountElement = Utils.getById('auth-flyover');
        this.accountInnerEl = Utils.getById('auth-flyover-inner');
        this.accountReject = Utils.getById('auth-reject');

        this.accountElement.addEventListener('click', this.shouldOverlayClose);
        this.accountReject.addEventListener('click', this.hide);
    }

    /**
     * On click of the main overlay element,
     * we check if it has it's own 'active' class.
     * If so we hide the entire element.
     * @param {Event} e
     */
    shouldOverlayClose(e) {
        const el = e.target || null;

        if (Utils.hasClass(el, ACCOUNT_CLASS)) {
            this.hide();
        }
    }

    /**
     * Shows a 'flyover' element above the form that tells the user
     * that they already have an account and adds a CTA to the register form.
     * @public
     */
    show() {
        Utils.addClass(this.accountElement, ACCOUNT_CLASS);
        Utils.addClass(this.accountInnerEl, ACCOUNT_INNER_CLASS);

        // Attach click handler to element to close on 'overlay click'
        this.accountElement.addEventListener('click', this.shouldOverlayClose);

        // Scroll to bottom of page
        window.scrollTo(0, document.body.scrollHeight);

        /**
         * Specifically checks for iOS devices.
         * Not an ideal solution but this is to tackle the 'no-scroll'
         * issue that's a constant pain on iOS.
         * Apparently from iOS 13 this will be fixed so this can be extended
         * to only return true < v13 (to be tested first).
         */
        const noScrollClass = DeviceDetection.isiOSDevice() ? NO_SCROLL_IOS_CLASS : NO_SCROLL_CLASS;

        // Add no-scroll class (only triggers on small breakpoint)
        Utils.addClass(document.documentElement, noScrollClass);
    }

    /**
     * Hides the above 'flyover' element
     * @param {Event} e
     * @private
     */
    hide(e) {
        if (e) {
            e.preventDefault();
        }

        Utils.removeClass(this.accountInnerEl, ACCOUNT_INNER_CLASS);

        /* setTimeout to animate the closing state */
        setTimeout(() => {
            Utils.removeClass(this.accountElement, ACCOUNT_CLASS);
            Utils.removeClass(document.documentElement, NO_SCROLL_CLASS);
            Utils.removeClass(document.documentElement, NO_SCROLL_IOS_CLASS);

            window.scrollTo(0, 0);

            // Remove previously attached event listener
            this.accountElement.removeEventListener('click', this.shouldOverlayClose);
        }, 500);
    }
}

export default AccountFlyover;
