import {Utils} from '@busuu/legacy-core';

const MASKED_SELECT_CLASS_SELECT = 'js-masked-select__select';
const MASKED_SELECT_CLASS_MASK = 'js-masked-select__mask';

/**
 * Class definition for a masked select component that will show a
 * different label to that of the select option.
 *
 * @example
 * const instance = new MaskedSelect(element, options);
 *
 * <div class="js-masked-select form__select-wrap">
 *   <select class="js-masked-select__select form__select">
 *     ...
 *   </select>
 *   <span class="js-masked-select__mask form__select-mask" />
 * </div>
 */
class MaskedSelect {
    /**
     * Create and initialize a masked select element
     * @param {Element} wrapperElement
     * @param {Object} options
     * @param {Array} options.values
     * @param {String} options.initialValue
     * @param {Function} options.onChange - Triggered on change of the select. Returns the full selected item.
     */
    constructor(wrapperElement, options = {}) {
        this.options = options;
        this.values = {};

        this.wrapperElement = wrapperElement;
        this.selectElement = null;
        this.maskElement = null;

        this.initSelectElements();
        this.initSelect();
    }

    /**
     * Convert values to dictionary for easy access
     * @param {Array} values
     */
    initSelectValues(values) {
        this.values = values.reduce(
            (dict, item) => ({
                ...dict,
                [item.value]: item,
            }),
            {}
        );
    }

    /**
     * Set the select elements
     */
    initSelectElements() {
        this.selectElement = Utils.getElementByClass(MASKED_SELECT_CLASS_SELECT, this.wrapperElement);
        this.maskElement = Utils.getElementByClass(MASKED_SELECT_CLASS_MASK, this.wrapperElement);
    }

    /**
     * Populate the select options with values
     */
    createSelectOptions() {
        const {values} = this.options;

        const optionFragment = document.createDocumentFragment();

        values.forEach(({value, label}) => {
            const option = document.createElement('option');

            option.value = value;
            option.label = label;

            Utils.setText(option, label);
            optionFragment.appendChild(option);
        });

        this.selectElement.appendChild(optionFragment);
    }

    /**
     * Update the currently selected item's mask
     * @param {Object} selectedItem
     */
    changeSelectedItem(selectedItem) {
        let maskText = '';

        if (selectedItem) {
            maskText = selectedItem.labelMask ? selectedItem.labelMask : selectedItem.label;
        }

        Utils.setText(this.maskElement, maskText);
    }

    onChangeSelect(e) {
        const {onChange} = this.options;
        const selectedItem = this.values[e.target.value];

        this.changeSelectedItem(selectedItem);

        if (onChange) {
            onChange(selectedItem);
        }
    }

    /**
     * Setup the select
     */
    initSelect() {
        const {initialValue, values} = this.options;

        this.initSelectValues(values);
        this.createSelectOptions();

        /**
         * Check if the select already has a value because if you duplicate page etc in
         * the browser it may be pre-populated and you don't want an empty mask
         */
        const initialSelectedItem = this.values[initialValue || this.selectElement.value];

        if (initialSelectedItem) {
            this.changeSelectedItem(initialSelectedItem);
            this.selectElement.value = initialSelectedItem.value;
        }

        this.selectElement.addEventListener('change', this.onChangeSelect.bind(this));
    }
}

export default MaskedSelect;
