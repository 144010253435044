/**
 * Returns a tracking access_type value from
 * a given type. This helper is used in
 * authentication and registration flows.
 * @param {String} type
 * @return {String}
 */
const getTrackingAccessType = (type) => {
    const accessTypes = {
        APPLE: 'apple',
        GOOGLE: 'google',
        FACEBOOK: 'facebook',
        'BUSUU-EMAIL': 'email',
        'BUSUU-PHONE': 'phone',
        AUTOLOGIN: 'autologin',
    };
    return accessTypes[type] || 'unknown_access_type';
};

export default {
    getTrackingAccessType,
};
