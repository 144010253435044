/* eslint-disable prefer-destructuring */
import {Utils, CountriesService, ExceptionService} from '@busuu/legacy-core';
import AuthenticationService from 'authentication/authentication-service.js';
import MobileService from 'mobile/mobile-service.ts';
import CaptchaService from 'captcha/captcha-service.js';
import QueryParameters from 'helpers/query-parameters.js';
import FormUtils from 'helpers/form-utils';
import AccountFlyover from 'registration/registration-busuu/account-flyover-class.js';
import RegistrationTracking from 'tracking/pages/tracking-register.js';
import EmailMobileSwitcher from 'components/forms/email-mobile-switcher-class.js';
import ConfigService from 'config/config-service.js';
import Tracking from 'tracking/tracking-controller';
import ReferralService from 'referral/referral-service';

// Private vars
let form;
let inputSwitcher;
let inputPassword;
let submitButton;
let errorElement;
let institutionParams;
let formLoader;
let accountFlyover;
let togglePassword;
let showPasswordIcon;
let hidePasswordIcon;
let forgotPasswordLink;

const filename = 'authentication-busuu.js';

const logError = (error) => {
    ExceptionService.handle('error', {
        filename,
        data: error,
    });
};

/**
 * After error is displayed we scroll top
 * to ensure to error is inside the viewport
 * and we enable again the form.
 */
const showError = (type = 'UNKNOWN_TYPE') => {
    const errorMessage = AuthenticationService.getErrorMessage(type);
    switch (type) {
        case 'ACCOUNT_EXPIRED':
            return accountFlyover.show();
        default:
            errorElement.innerHTML = errorMessage;
            FormUtils.showElement(errorElement);
            window.scrollTo(0, 0);
    }
};

const hideError = () => {
    if (errorElement) {
        Utils.addClass(errorElement, 'hidden');
    }
};

const redirect = function (url) {
    window.location.href = url;
};

const getAccessType = () => {
    if (!inputSwitcher) {
        return 'unknown';
    }
    return inputSwitcher.getState() === 'mobile' ? 'BUSUU-PHONE' : 'BUSUU-EMAIL';
};

const onSwitchFieldType = (state) => {
    forgotPasswordLink.href = forgotPasswordLink.getAttribute(`href-${state}`);
};

/**
 * Error handling
 */
const displayUIError = (type) => {
    showError(type);
    FormUtils.stopButtonLoading(submitButton);
};

const onNonceError = ({type = 'UNKNOWN_TYPE'}) => {
    const accessType = getAccessType();
    AuthenticationService.sendNonceErrorTracking(accessType, {error: type});
    displayUIError(type);
};

const onAuthError = ({type = 'UNKNOWN_TYPE'}) => {
    const accessType = getAccessType();
    AuthenticationService.sendLoginErrorTracking(accessType, {error: type});
    displayUIError(type);
};

/**
 * Success
 */
const onAuthSuccess = (response) => {
    if (response.backendRedirection) {
        return redirect(response.backendRedirection);
    }
    const accessType = getAccessType();
    return AuthenticationService.sendLoginSuccessTracking(accessType).then(() => {
        let url = form.getAttribute('action');
        if (institutionParams && institutionParams.institution_code) {
            url = url.replace('{email}', encodeURIComponent(inputSwitcher.value()));
        }
        redirect(url);
    });
};

const onNonceSuccess = (response) => {
    const accessType = getAccessType();
    return AuthenticationService.sendNonceSuccessTracking(accessType).then(
        MobileService.callbackMobileAppAfterAuthenticationOrRegistration.bind(null, {
            accessType,
            source: 'login',
            ...response,
        })
    );
};

const authUserOnBusuu = (captchaToken) => {
    let email = inputSwitcher.value();
    if (inputSwitcher.getState() === 'mobile') {
        email = email.replace('+', '');
    }

    const perkToken = ReferralService.getPerkToken();

    const platformSource = AuthenticationService.getPlatformSource();
    const requestParams = {
        email,
        password: inputPassword.value,
        platform: platformSource,
    };

    if (captchaToken) {
        requestParams.captcha_token = captchaToken;
    }

    if (perkToken) {
        requestParams.perk_token = perkToken;
    }

    /**
     * Add params to the resquest for institutions
     */
    institutionParams = QueryParameters.getInstitutionParameters();
    if (institutionParams.institution_code) {
        requestParams.institution_data = {
            name: institutionParams.institution_name,
            code: institutionParams.institution_code,
        };
    }

    if (MobileService.isAuthenticationOrRegistrationMobileFlow()) {
        return AuthenticationService.getAuthNonce(requestParams).then(onNonceSuccess).catch(onNonceError);
    }

    return AuthenticationService.authUser(requestParams).then(onAuthSuccess).catch(onAuthError);
};

const onFormSubmit = (e) => {
    if (e) {
        e.preventDefault();
    }

    hideError();
    FormUtils.setButtonLoading(submitButton);

    const onSuccess = (result) => {
        return authUserOnBusuu(result.token);
    };

    const captchaParams = {
        endpointType: 'login',
        containerID: 'login-recaptcha',
    };

    CaptchaService.initializeAndRenderChallenge(captchaParams).then(onSuccess).catch(onAuthError);
};

const initMobileEmailFieldSwitcher = (twoFactorAuthenticationEnabled, countryCode) => {
    const fieldSwitchWrapper = Utils.getById('login-form-field-switch');
    const initialState = AuthenticationService.getEmailMobileSwitcherInitialState(twoFactorAuthenticationEnabled);
    let initialSelectValue = null;
    if (countryCode) {
        try {
            initialSelectValue = CountriesService.getSelectedCountryPhoneCode(countryCode);
        } catch (error) {
            logError(error);
        }
    }

    inputSwitcher = new EmailMobileSwitcher(fieldSwitchWrapper, {
        onStateChange: onSwitchFieldType,
        initialState,
        initialSelectValue,
    });

    inputSwitcher.focus();
};

const initForm = (twoFactorAuthenticationEnabled, countryCode) => {
    form.addEventListener('submit', onFormSubmit);

    initMobileEmailFieldSwitcher(twoFactorAuthenticationEnabled, countryCode);
    if (twoFactorAuthenticationEnabled) {
        FormUtils.hideElement(Utils.getById('login-form-socials'));
    }

    FormUtils.hideElement(formLoader);
    FormUtils.showElement(form);
};

const initFormType = () => {
    const onComplete = ({twoFactorAuthenticationEnabled, countryCode}) => {
        initForm(twoFactorAuthenticationEnabled, countryCode);
    };

    const onError = () => ({
        twoFactorAuthenticationEnabled: false,
        countryCode: null,
    });

    const onCodeException = (error) => {
        logError(error);
        showError('RUNTIME_ERROR');

        FormUtils.hideElement(formLoader);
        FormUtils.showElement(form);
    };

    ConfigService.getTwoFactorAuthConfig().catch(onError).then(onComplete).catch(onCodeException);
};

const onTogglePassword = (e) => {
    if (e) e.preventDefault();
    const isHidden = inputPassword.getAttribute('type') === 'password';
    const type = isHidden ? 'text' : 'password';
    inputPassword.setAttribute('type', type);
    FormUtils.toggleElement(showPasswordIcon, isHidden);
    FormUtils.toggleElement(hidePasswordIcon, !isHidden);
    if (isHidden) {
        RegistrationTracking.sendShowPasswordSelectedTracking();
    } else {
        RegistrationTracking.sendHidePasswordSelectedTracking();
    }
};

const initShowPassword = (e) => {
    if (e) e.preventDefault();
    if (inputPassword && togglePassword) {
        togglePassword.addEventListener('click', onTogglePassword);
    }
};

const onPasswordInput = () => {
    return inputPassword.value ? Utils.removeClass(togglePassword, 'hidden') : Utils.addClass(togglePassword, 'hidden');
};

const initPasswordEyeToggle = () => {
    if (inputPassword) inputPassword.addEventListener('input', onPasswordInput);
};

const init = () => {
    form = Utils.getById('login-form');
    const link = Utils.getById('company-login-form__link');
    ReferralService.storePerkToken(); // saving perk token to localStorage

    if (link) {
        link.addEventListener('click', (event) => {
            event.preventDefault();
            Tracking.sendAndWait('B2B_LOGIN_CLICK');
            const redirectLink = event.target.getAttribute('href');

            // In case it's a mobile SSO auth flow, redirect from webview to the browser to perform SSO
            if (MobileService.isAuthenticationOrRegistrationMobileFlow()) {
                MobileService.callbackMobileAppAfterAuthenticationOrRegistration({
                    backendRedirection: redirectLink,
                    source: 'login',
                    accessType: 'BUSUU-EMAIL',
                    nonce: null,
                });
            } else {
                window.location.href = redirectLink;
            }
        });
    }

    if (form) {
        inputPassword = Utils.getById('login-form-password');
        forgotPasswordLink = Utils.getById('auth-form-forgot-password');
        errorElement = Utils.getById('error-generic');
        submitButton = Utils.getById('login-form-submit');
        formLoader = Utils.getById('login-form-loader');
        showPasswordIcon = Utils.getById('form-show-password-icon');
        hidePasswordIcon = Utils.getById('form-hide-password-icon');
        togglePassword = Utils.getById('toggle-password');
        accountFlyover = new AccountFlyover();

        initShowPassword();
        initFormType();
        initPasswordEyeToggle();
    }
};

init();
