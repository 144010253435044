/* eslint-disable no-undef */
import {Utils} from '@busuu/legacy-core';
import AuthenticationService from 'authentication/authentication-service.js';
import CaptchaService from 'captcha/captcha-service.js';
import TranslationsService from 'common/translations.service.ts';
import FormUtils from 'helpers/form-utils';
import ReferralService from 'referral/referral-service';
import {AuthenticationGoogleConstant} from './authentication-constants';
import {getWidthWithRetry} from '../helpers/html-helpers';

let errorElement;
let GGButton;

/**
 * Error Tracking sent after Google API
 * fail or Busuu API fail
 */
const sendTrackingError = (type) => {
    AuthenticationService.sendLoginErrorTracking('GOOGLE', {error: type});
};

/**
 * Returns the error string for an user already registered
 * @return {String}
 */
const getUserAlreadyRegisteredMessage = () => {
    let message = '';
    const dataUrl = errorElement.getAttribute('data-login-url');
    message += TranslationsService.getTranslation('USER_ALREADY_EXISTS');
    if (dataUrl) {
        message += `<br><a href=${dataUrl}>${TranslationsService.getTranslation('LOGIN')}</a>`;
    }
    return message;
};

/**
 * Returns the error string for an user that tries to login
 * with a not existing account
 * @return {String}
 */
const getUserNotExistingMessage = () => {
    let message = '';
    const dataUrl = errorElement.getAttribute('data-register-url');
    message += TranslationsService.getTranslation('USER_DOESNT_EXIST');
    if (dataUrl) {
        message += `<br><a href=${dataUrl}>${TranslationsService.getTranslation('REGISTER')}</a>`;
    }
    return message;
};

/**
 * Get the error message to display based on the type of the error
 * @return {String}
 */
const getErrorTextMessage = (type) => {
    switch (type) {
        case 'USER_REGISTERED':
            return getUserAlreadyRegisteredMessage();
        case 'USER_NOT_REGISTERED':
            return getUserNotExistingMessage();
        default:
            return `${TranslationsService.getTranslation('ERROR')} - ${TranslationsService.getTranslation(
                'TRY_AGAIN'
            )}`;
    }
};

/**
 * Hide/Show Social Error
 */
const showError = (type, status) => {
    const code = status ? `[${status} - ${type}]` : `[${type}]`;
    const message = `${getErrorTextMessage(type)}<br><small>${code} - GG</small>`;
    errorElement.innerHTML = message;
    FormUtils.showElement(errorElement);
    window.scrollTo(0, 0);
};

const handleError = ({type = 'UNKNOWN_TYPE', status = null}) => {
    const ignoreError = ['GG_POPUP_CLOSED'].includes(type);
    sendTrackingError(type);
    if (!ignoreError) {
        showError(type, status);
    }
};

const redirect = (url) => {
    window.location.href = url;
};

const onAuthSuccess = (response) => {
    if (response.backendRedirection) {
        return redirect(response.backendRedirection);
    }
    return AuthenticationService.sendLoginSuccessTracking('GOOGLE').then(() => {
        const url = GGButton.getAttribute('data-redirect');
        redirect(url);
    });
};

const authUser = (googleToken, captchaToken) => {
    const params = {token: googleToken};
    const perkToken = ReferralService.getPerkToken();
    if (captchaToken) {
        params.captcha_token = captchaToken;
    }

    if (perkToken) {
        params.perk_token = perkToken;
    }
    return AuthenticationService.authGoogleUserOnBusuu(params).then(onAuthSuccess);
};

const initCaptcha = (googleToken) => {
    const onCaptchaInit = (result) => {
        return authUser(googleToken, result.token);
    };
    CaptchaService.initializeAndRenderChallenge({
        endpointType: 'login_vendor',
        containerID: 'login-recaptcha',
    })
        .then(onCaptchaInit)
        .catch(handleError);
};

const handleCredentialResponse = (googleResponse = {}) => {
    const googleToken = googleResponse.credential;
    initCaptcha(googleToken);
};

const init = () => {
    GGButton = Utils.getById(AuthenticationGoogleConstant.googleSigninButtonName);
    errorElement = Utils.getById('error-generic');
    const userLocal = GGButton.getAttribute('data-locale');

    window.onload = async () => {
        if (!window.google) return;
        google.accounts.id.initialize({
            client_id: AuthenticationGoogleConstant.clientId,
            context: 'signin',
            ux_mode: 'popup',
            auto_prompt: 'false',
            callback: handleCredentialResponse,
        });

        let socialsWidthValue;
        try {
            socialsWidthValue = await getWidthWithRetry('login-form-socials');
        } catch (error) {
            // Silently fail.
            socialsWidthValue = 280; // fallback to old hardcoded value
        }

        google.accounts.id.renderButton(GGButton, {
            theme: 'outline',
            size: 'large',
            shape: 'pill',
            type: 'standard',
            text: 'continue_with',
            locale: userLocal,
            width: `${socialsWidthValue - 1}px`,
            logo_alignment: 'left',
        });
    };
};

init();
