import Tracking from 'tracking/tracking-controller.ts';
import TrackingService from 'tracking/tracking-service.js';
import QueryParameters from 'helpers/query-parameters.js';
import ReferralService from 'referral/referral-service.js';
import MobileService from 'mobile/mobile-service.ts';

let twoFactorAuth = false;

/**
 * Returns the tracking data to send on success or failure.
 * @param  {String} type
 * @param  {Object} params
 * @return {Object}
 * @private
 */
const getTrackingData = (type = null, params = {}) => {
    const optedInDefined = typeof params.email_opt_in === 'boolean';
    const referralCode = ReferralService.getReferralUserCode();
    const perkToken = ReferralService.getPerkToken();
    const registrationOrigin = QueryParameters.getRegistrationOriginParameter();
    const isAuthenticationOrRegistrationMobileFlow = MobileService.isAuthenticationOrRegistrationMobileFlow();
    const allParams = {
        ...params,
        two_factor_auth: twoFactorAuth,
        ...(type && {
            access_type: TrackingService.getTrackingAccessType(type),
        }),
        ...(registrationOrigin && {
            registration_origin: registrationOrigin,
        }),
        ...(optedInDefined && {
            email_opt_in: params.email_opt_in ? 'enabled' : 'disabled',
        }),
        ...(!isAuthenticationOrRegistrationMobileFlow && {
            email_soft_opt_in: true,
        }),
        ...(referralCode && {
            referral_token: referralCode,
        }),
        ...(perkToken && {
            perk_token: perkToken,
        }),
    };

    return allParams;
};

/**
 * Function to be invoked when a registration successfully happened
 * to send the tracking. We resolve the promise only after a certain
 * time to let the tracking go. (There's additional calls from the tracking
 * controller just after we identfied a new user (gtm trackers, etc.))
 * @param  {String} type
 * @param  {Object} params - additional params, optional
 * @return {Promise}
 */
const sendRegistrationSuccessTracking = (type, params = {}) => {
    return Tracking.sendAndWait('USER_REGISTERED_SUCCESS', getTrackingData(type, params));
};

/**
 * @param  {String} type
 * @param  {Object} params - additional params, optional
 * @return {Promise}
 */
const sendRegistrationErrorTracking = (type, params = {}) => {
    return Tracking.sendAndWait('USER_REGISTERED_FAILED', getTrackingData(type, params));
};

/**
 * @param  {String} type
 * @param  {Object} params - additional params, optional
 * @return {Promise}
 */
const sendRegistrationWithNonceSuccessTracking = (type, params = {}) => {
    return Tracking.sendAndWait('REGISTER_NONCE_SUCCESS', getTrackingData(type, params));
};

/**
 * @param  {String} type
 * @param  {Object} params - additional params, optional
 * @return {Promise}
 */
const sendRegistrationWithNonceErrorTracking = (type, params = {}) => {
    return Tracking.sendAndWait('REGISTER_NONCE_ERROR', getTrackingData(type, params));
};

/**
 * @return {Promise}
 */
const sendRegistrationVerificationTracking = () => {
    return Tracking.send('VERIFICATION_CODE_PAGE_VIEWED', getTrackingData());
};

/**
 * @return {Promise}
 */
const sendRegistrationVerificationEnteredTracking = () => {
    return Tracking.send('VERIFICATION_CODE_ENTERED', getTrackingData());
};

/**
 * @return {Promise}
 */
const sendHidePasswordSelectedTracking = () => {
    return Tracking.send('HIDE_PASSWORD_SELECTED', getTrackingData());
};

/**
 * @return {Promise}
 */
const sendShowPasswordSelectedTracking = () => {
    return Tracking.send('SHOW_PASSWORD_SELECTED', getTrackingData());
};

/**
 * Initialise the tracking for the registration
 * @param {Boolean} twoFactorAuthEnabled
 */
const init = (twoFactorAuthEnabled) => {
    twoFactorAuth = twoFactorAuthEnabled;
    Tracking.send(
        'PAGE_VIEW_REGISTER_PAGE',
        getTrackingData(null, {
            url: window.location.href,
        })
    );
};

const TrackingRegister = {
    init,
    sendRegistrationErrorTracking,
    sendRegistrationSuccessTracking,
    sendRegistrationVerificationTracking,
    sendRegistrationVerificationEnteredTracking,
    sendRegistrationWithNonceSuccessTracking,
    sendRegistrationWithNonceErrorTracking,
    sendShowPasswordSelectedTracking,
    sendHidePasswordSelectedTracking,
};

export default TrackingRegister;
