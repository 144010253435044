import {Utils} from '@busuu/legacy-core';
import PubSub from 'pubsub-js/src/pubsub.js';
import AuthenticationService from 'authentication/authentication-service.js';
import CaptchaService from 'captcha/captcha-service.js';
import TranslationsService from 'common/translations.service.ts';
import FormUtils from 'helpers/form-utils';
import ReferralService from 'referral/referral-service';

/**
 * Private vars
 */
let errorElement;
let FBButton;
let FBButtonInner = null;
const disabledClass = 'social-auth--disabled';

/**
 * Enable/Disable button state
 */
const enableButton = () => {
    Utils.removeClass(FBButton, disabledClass);
};

const disableButton = () => {
    Utils.addClass(FBButton, disabledClass);
};

/**
 * Enable/Disable button state with loading
 */
const enableButtonAndDisableLoading = () => {
    enableButton();
    Utils.removeClass(FBButtonInner, 'main-spinner-a');
};

const disableButtonAndEnableLoading = () => {
    disableButton();
    Utils.addClass(FBButtonInner, 'main-spinner-a');
};

/**
 * Error Tracking sent after Facebook API
 * fail or Busuu API fail
 */
const sendTrackingError = (type) => {
    AuthenticationService.sendLoginErrorTracking('FACEBOOK', {error: type});
};

/**
 * Returns the error string for an user already registered
 * @return {String}
 */
const getUserAlreadyRegisteredMessage = () => {
    let message = '';
    const dataUrl = errorElement.getAttribute('data-login-url');
    message += TranslationsService.getTranslation('USER_ALREADY_EXISTS');
    if (dataUrl) {
        message += `<br><a href=${dataUrl}>${TranslationsService.getTranslation('LOGIN')}</a>`;
    }
    return message;
};

/**
 * Returns the error string for an user that tries to login
 * with a not existing account
 * @return {String}
 */
const getUserNotExistingMessage = () => {
    let message = '';
    const dataUrl = errorElement.getAttribute('data-register-url');
    message += TranslationsService.getTranslation('USER_DOESNT_EXIST');
    if (dataUrl) {
        message += `<br><a href=${dataUrl}>${TranslationsService.getTranslation('REGISTER')}</a>`;
    }
    return message;
};

/**
 * Get the error message to display based on the type of the error
 * @return {String}
 */
const getErrorTextMessage = (type) => {
    switch (type) {
        case 'USER_REGISTERED':
            return getUserAlreadyRegisteredMessage();
        case 'USER_NOT_REGISTERED':
            return getUserNotExistingMessage();
        default:
            return `${TranslationsService.getTranslation('ERROR')} - ${TranslationsService.getTranslation(
                'TRY_AGAIN'
            )}`;
    }
};

/**
 * Hide/Show Social Error
 */
const showError = (type, status) => {
    const code = status ? `[${status} - ${type}]` : `[${type}]`;
    const message = `${getErrorTextMessage(type)}<br><small>${code} - FB</small>`;
    if (errorElement) {
        errorElement.innerHTML = message;
        FormUtils.showElement(errorElement);
        window.scrollTo(0, 0);
    }
};

const hideError = () => {
    FormUtils.hideElement(errorElement);
};

const handleError = ({type = 'UNKNOWN_TYPE', status = null}) => {
    sendTrackingError(type);
    enableButtonAndDisableLoading();
    showError(type, status);
};

const redirect = (url) => {
    window.location.href = url;
};

const onAuthSuccess = (response) => {
    if (response.backendRedirection) {
        return redirect(response.backendRedirection);
    }
    return AuthenticationService.sendLoginSuccessTracking('FACEBOOK').then(() => {
        const url = FBButton.getAttribute('data-redirect');
        redirect(url);
    });
};

const authFacebookUserOnBusuu = (facebookToken, captchaToken) => {
    const params = {token: facebookToken};
    const perkToken = ReferralService.getPerkToken();
    if (captchaToken) {
        params.captcha_token = captchaToken;
    }

    if (perkToken) {
        params.perk_token = perkToken;
    }
    return AuthenticationService.authFacebookUserOnBusuu(params).then(onAuthSuccess);
};

const initCaptcha = (facebookToken) => {
    const onSuccess = (result) => {
        return authFacebookUserOnBusuu(facebookToken, result.token);
    };
    return CaptchaService.initializeAndRenderChallenge({
        endpointType: 'login_vendor',
        containerID: 'login-recaptcha',
    }).then(onSuccess);
};

const checkLoginState = (e) => {
    e.preventDefault();
    disableButtonAndEnableLoading();
    hideError();
    AuthenticationService.authUserOnFacebook().then(initCaptcha).catch(handleError);
};

const init = () => {
    FBButton = Utils.getById('facebook-authentication');
    if (FBButton) {
        FBButtonInner = Utils.getElementByClass('js-facebook-inner', FBButton);
        errorElement = Utils.getById('error-generic');
        FBButton.addEventListener('click', checkLoginState);
        disableButton();
        PubSub.subscribe('FACEBOOK_API_LOADED', enableButton);
    }
};

init();
