import {Utils} from '@busuu/legacy-core';
import Tracking from 'tracking/tracking-controller.ts';
import ConfigService from 'config/config-service.js';

/**
 * To add a reCaptcha on a page you need to:
 * - Add a "<div id="login-recaptcha"></div>" to your html (google will render an invisible captcha there)
 * - Call CaptchaService.getCaptchaConfig on page load to ask BE if captcha is required
 *   and load the relevant script for captcha
 * - Replace normal submit click with a new function onCaptchaSubmit
 * - That function should call preventDefault(), disable the button and call CaptchaService.renderChallenge()
 * - The CaptchaService.renderChallenge() calls Google if needed and then execute a callback to resume the normal flow
 *
 * Endpoint name can be:
 * "login|login_vendor|register|register_vendor|forgotten_password|reset_password|
 * mailing_unsubscribe|pro_feedback|feedback"
 *
 * For more info:
 * BE docs: https://busuucom.atlassian.net/wiki/spaces/BB/pages/915636575/Invisible+Captcha
 * FE Docs: https://busuucom.atlassian.net/wiki/spaces/FRON/pages/1012367544/Captcha+logic
 */
// Private vars
const CAPTCHA_SITE_KEY = '6Lfjg3UUAAAAACnwIC4-Jv5ySjzuLL2VhPNfrD8m'; // This is a public key
const CAPTCHA_SDKS = {
    default: 'https://www.google.com/recaptcha/api.js', // For everyone that allows google api
    alternative: 'https://www.recaptcha.net/recaptcha/api.js', // For countries that don't like google (ie: china...)
};
let captchaWidgetID = null; // Associated ID generated after grecaptcha.render() has been invoked

const injectScript = (url, onErrorCallback) => {
    const js = document.createElement('script');
    const head = document.getElementsByTagName('head')[0];
    js.async = true;
    js.defer = true;
    js.src = url;
    js.onerror = onErrorCallback;
    head.appendChild(js);
};

/**
 * After each Token fetch, we need to completely destroy the div
 * or you will get an error on the second attempt.
 * 1. This prevent the reset method to fail if no captcha
 *    challenged has been invoked yet.
 * @private
 */
// eslint-disable-next-line no-shadow
const resetCaptcha = (containerID, captchaWidgetID) => {
    if (captchaWidgetID === null) {
        return false; // 1
    }

    window.grecaptcha.reset(captchaWidgetID);

    // Destroy the existing node and replace with a new one
    const oldNode = document.getElementById(containerID);
    if (oldNode && oldNode.parentNode) {
        const newNode = document.createElement('div');
        newNode.id = containerID;
        oldNode.parentNode.insertBefore(newNode, oldNode.nextSibling);
        oldNode.parentNode.removeChild(oldNode);
    }
};

/**
 * Loads Google reCaptcha SDK.
 * @param {String} captchaURL
 * @return {Promise}
 * @private
 */
const loadSDK = (captchaURL) => {
    return new Promise((resolve, reject) => {
        window.onloadCallback = () => {
            resolve();
        };

        const onError = () => {
            reject({type: 'CAPTCHA_ERROR_SDK_LOADING'});
        };

        injectScript(`${captchaURL}?onload=onloadCallback`, onError);
    });
};

/**
 * Invokes the reCaptcha challenge on the page.
 * Needs a containerID for visible rendering if Google decides it.
 * This method sends the tracking as well based on the endpoint type.
 *
 * NB:
 * We're not using the 'expired-callback' callback available
 * within the grecaptcha.render() method scope.
 * After few tests it seems that this expired callback
 * is always invoked: on error or on success of the challenge.
 * There's no current flow where this callback is useful for us
 * so we've decided not using it. It seems like a bug or
 * an intended behaviour from Google.
 *
 * A unhandled error timeout promise error will appear
 * between different captcha retries, it's due to the grecaptcha.reset().
 * Source: https://github.com/google/recaptcha/issues/269
 *
 * @param {String} endpoint
 * @param {String} containerID
 * @return {Promise}
 * @private
 */
const renderChallenge = (endpointType, containerID) => {
    return new Promise((resolve, reject) => {
        if (!window.grecaptcha) {
            reject({type: 'CAPTCHA_ERROR_SDK_MISSING'});
            return false;
        }

        const onCaptchaSuccess = (token) => {
            resolve(token);
        };

        const onCaptchaError = () => {
            reject({type: 'CAPTCHA_ERROR_CHALLENGE_FAILED'});
        };

        resetCaptcha(containerID, captchaWidgetID);

        Tracking.send('CAPTCHA_STARTED', {
            endpoint: endpointType,
        });

        // Render an invisible captcha on the page in the DOM (params.id)
        captchaWidgetID = window.grecaptcha.render(containerID, {
            size: 'invisible',
            sitekey: CAPTCHA_SITE_KEY,
            callback: onCaptchaSuccess,
            'error-callback': onCaptchaError,
        });

        /**
         * Run a captcha check that will call the captchaCallback directly
         * for a "human" or after a challenge for "potential bot".
         */
        window.grecaptcha.execute(captchaWidgetID);
    });
};

/**
 * Indicates whether or not a captcha is active
 * based on the backend config.
 * @param  {Array}  activeEndpoints
 * @param  {String}  endpointType
 * @return {Boolean}
 * @private
 */
const isCaptchaActive = (activeEndpoints, endpointType) => {
    if (!Utils.isArray(activeEndpoints)) {
        return false;
    }
    return Boolean(activeEndpoints.indexOf(endpointType) !== -1);
};

/**
 * Calls the backend config and initialize the captcha SDK
 * based on the backend configuration and the given endpointType.
 * Then renders the challenge in the page and returns the token.
 * @param  {String} endpointType - endpoint used in the flow (tracking)
 * @param  {String} containerID - ID of the container div where the challenge is rendered
 * @return {Promise}
 */
const initializeAndRenderChallenge = ({endpointType, containerID} = {}) => {
    const invokeReCaptcha = () => {
        const onSuccess = (token) => {
            return {
                active: true,
                token,
            };
        };
        return renderChallenge(endpointType, containerID).then(onSuccess);
    };

    const loadSDKOnCondition = (config) => {
        if (!isCaptchaActive(config.captcha_enabled_endpoints, endpointType)) {
            return {
                active: false,
            };
        }
        return loadSDK(CAPTCHA_SDKS[config.captcha_url]).then(invokeReCaptcha);
    };

    const onSuccess = (params = {}) => {
        const captchaActive = Boolean(params.active);
        if (captchaActive) {
            Tracking.send('CAPTCHA_PASSED', {
                endpoint: endpointType,
            });
        }

        return {
            active: params.active || false,
            token: params.token || null,
            endpointType,
        };
    };

    const onError = (error = {}) => {
        const type = error.type || 'CAPTCHA_ERROR_UNKNOWN_TYPE';
        Tracking.send('CAPTCHA_NOT_PASSED', {
            endpoint: endpointType,
            type,
        });
        throw {
            type,
        };
    };

    return ConfigService.getCaptchaConfig().then(loadSDKOnCondition).then(onSuccess).catch(onError);
};

const CaptchaService = {
    initializeAndRenderChallenge,
};

export default CaptchaService;
