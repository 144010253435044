import {Utils, ExceptionService} from '@busuu/legacy-core';
import SharedDataService from 'data/shared-data-service.js';
import endpoints from 'endpoint/endpoint-service.js';
import CurrentUser from 'user/current-user-service.js';
import Tracking from 'tracking/tracking-controller.ts';
import TrackingService from 'tracking/tracking-service.js';
import PostAuthenticationService from 'authentication/post-authentication-service.js';
import APIRequestsService from 'endpoint/api-requests-service.js';
import TranslationsService from 'common/translations.service.ts';
import QueryParameters from 'helpers/query-parameters.js';
import MobileService from 'mobile/mobile-service.ts';
import {decomposeAxiosError, composeAxiosError} from 'endpoint/api-requests-service.helpers.js';
import {ERROR_BAD_RESPONSE_FORMAT, ERROR_PARAMS_MISSING} from 'endpoint/api-requests-service.constants.js';

const filename = 'authentication-service.js';

const logError = (message, data) => {
    ExceptionService.handle('warning', {filename, message, data});
};

/**
 * Indicates whether or not a redirection needs to happen
 * based on the server side response.
 * Likely a B2B flow:
 * https://busuucom.atlassian.net/wiki/spaces/FRON/pages/5238849622/Login+via+Web+from+Mobile+Front-end#The-B2B-Flow
 * @param  {Object} response
 * @return {String|null}
 * @private
 */
const getBackendRedirect = (response) => {
    const redirectPresent = PostAuthenticationService.getBackendRedirectionLocation(response);
    return redirectPresent || null;
};

/**
 * When we log in new users, we refresh the session by
 * removing session storage keys.
 * @private
 */
const refreshSession = () => {
    SharedDataService.clearSpecificKeysAfterLogin();
};

/**
 * When a backend redirection is detected,
 * we mutate the data to pass it to the response back to the controllers.
 * 1. We also ensure that we remove all shared storage as we want to have a
 * brand new version of storage (payments, datas shared, page views, etc.).
 * @param {Object} response
 * @param {String} backendRedirection
 * @returns {Object}
 * @private
 */
const onBackendRedirection = (response, backendRedirection) => {
    response.data.backendRedirection = backendRedirection;
    refreshSession(); // 1
    return response.data;
};

/**
 * After logged is success,
 * - 1. We remove all shared storage as we want to have a
 *   brand new version of storage (payments, datas shared, page views, etc.).
 * - 2. We then force the new user data to be fetched again on the session
 *   to ensure post operations that requires the user will provide the
 *   new user data (eg: Tracking).
 * @private
 */
const refreshSessionAndGetUser = () => {
    refreshSession(); // 1
    return CurrentUser.get({force: true}).catch(() => {}); // 2
};

/**
 * Logs in an existing user on Busuu
 * @param {Object} params
 * @param {String} params.email
 * @param {String} params.password
 * @return {Promise}
 */
const authUser = (params) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`authUser() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onComplete = (response) => {
        return response.data;
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        const backendRedirection = getBackendRedirect(response);
        if (backendRedirection) {
            return onBackendRedirection(response, backendRedirection);
        }

        return refreshSessionAndGetUser().then(onComplete.bind(null, response));
    };

    if (!params) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.getCaptchaBypassedEndpoint('login');

    return APIRequestsService.post(url, params).then(onSuccess).catch(onError);
};

/**
 * Returns a nonce token after a user is identified. This nonce token can
 * then be used to retrieve an access-token.
 * NB: This doesn't authenticate a user.
 * Documentation: https://busuucom.atlassian.net/wiki/spaces/BB/pages/5078646818/Login+via+Web
 * @param {Object} params
 * @param {String} params.email
 * @param {String} params.password
   @return {Promise}
 */
const getAuthNonce = (params) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);
        logError(`getAuthNonce() error: ${applicationCode} - ${message}`, error);
        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onSuccess = (response) => {
        if (!response) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        const backendRedirection = getBackendRedirect(response);
        if (response.data && backendRedirection) {
            return {
                nonce: null,
                backendRedirection,
            };
        }

        if (response.nonce) {
            return {
                nonce: response.nonce,
                backendRedirection: null,
            };
        }

        return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
    };

    if (!params) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.getCaptchaBypassedEndpoint('login');
    params.token_format = 'nonce';

    return APIRequestsService.post(url, params).then(onSuccess).catch(onError);
};

/**
 * Log a new user on Busuu based on Facebook token.
 * After Facebook authentication is done,
 * we are trying to login the user within the Busuu app
 * @params  {Object} - params: { token: {String} }
 * @return {Promise}
 */
const authFacebookUserOnBusuu = (params) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`authFacebookUserOnBusuu() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onComplete = (response) => {
        return response.data;
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        const backendRedirection = getBackendRedirect(response);
        if (backendRedirection) {
            return onBackendRedirection(response, backendRedirection);
        }

        return refreshSessionAndGetUser().then(onComplete.bind(null, response));
    };

    if (!params) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('loginFacebook');

    return APIRequestsService.post(url, params).then(onSuccess).catch(onError);
};

/**
 * Log a new user on Busuu based on Google token.
 * After Google authentication is done,
 * we are trying to login the user within the Busuu app
 * @params  {Object} - params: { token: {String} }
 * @return {Promise}
 */
const authGoogleUserOnBusuu = (params) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`authGoogleUserOnBusuu() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onComplete = (response) => {
        return response.data;
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        const backendRedirection = getBackendRedirect(response);
        if (backendRedirection) {
            return onBackendRedirection(response, backendRedirection);
        }

        return refreshSessionAndGetUser().then(onComplete.bind(null, response));
    };

    if (!params) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('loginGoogle');
    return APIRequestsService.post(url, params).then(onSuccess).catch(onError);
};

/**
 * Log a new user on Busuu based on Apple token.
 * After Apple authentication is done,
 * we are trying to login the user within the Busuu app
 * @params  {Object} - params: { token: {String} }
 * @return {Promise}
 */
const authAppleUserOnBusuu = (params) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`authAppleUserOnBusuu() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onComplete = (response) => {
        return response.data;
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        const backendRedirection = getBackendRedirect(response);
        if (backendRedirection) {
            return onBackendRedirection(response, backendRedirection);
        }

        return refreshSessionAndGetUser().then(onComplete.bind(null, response));
    };

    if (!params) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('loginApple');
    return APIRequestsService.post(url, params).then(onSuccess).catch(onError);
};

/**
 * Semi-authenticate a given user
 * to access payments APIs in order to be able
 * to allow some users that are already registered but not
 * authenticated to make a payment directly.
 * This will not authenticate the user and he can't access the webapp
 * or other APIs at this level.
 * @param  {Object} params
 * @param  {String} params.email
 * @return {Promise}
 */
const autoAuthUser = (params) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`autoAuthUser() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return response.data;
    };

    if (!params || !params.email) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('autoauth');
    return APIRequestsService.post(url, params).then(onSuccess).catch(onError);
};

/**
 * Fully authenticate a user using a token.
 * This API acts like a normal login flow
 * but without the email/phone + password combination.
 * @param  {String} token
 * @param  {String} type - Authentication type, see Backend docs
 * @return {Promise}
 */
const autoLoginUser = (token, type) => {
    const onError = (error) => {
        const {applicationCode, message} = decomposeAxiosError(error);

        logError(`autoLoginUser() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message,
        });
    };

    const onComplete = (response) => {
        return response.data;
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return refreshSessionAndGetUser().then(onComplete.bind(null, response));
    };

    if (!token || !type) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('autologin').replace('{type}', type);
    return APIRequestsService.post(url, {token}).then(onSuccess).catch(onError);
};

/**
 * Logs a given user on Facebook using their login API.
 * Facebook will ask the user to log in or to access the
 * Busuu app using a prompt dialog. After the user is authenticated
 * the promise is resolved and returns the identification token.
 * @return {Promise}
 */
const authUserOnFacebook = () =>
    new Promise((resolve, reject) => {
        const onError = (type, error) => {
            logError(`authUserOnFacebook() - Failed to authenticate on Facebook: ${type}`, error);
            reject({type});
        };

        const onSuccess = (response = {}) => {
            const token = response.authResponse ? response.authResponse.accessToken : null;
            if (!token) {
                return onError('FB_BAD_RESPONSE_FORMAT', response);
            }
            resolve(token);
        };

        if (!window.FB) {
            onError('FB_API_NOT_FOUND');
        }

        /**
         * If user isn't authenticated, this prompts a
         * popup to ask the user to authenticate himself on Facebook.
         * N.B.: An error here means that the user closed or cancelled the flow (discard).
         *
         * see https://developers.facebook.com/docs/facebook-login/permissions for scopes documentation
         */
        const fbLogin = () => {
            window.FB.login(
                (response) => {
                    return response.authResponse ? onSuccess(response) : onError('FB_AUTH_FAILURE', response);
                },
                {
                    scope: 'email',
                }
            );
        };

        /*
         * Checks if the app is authorized and
         * if the user is logged in Facebook.
         */
        window.FB.getLoginStatus((response = {}) => {
            switch (response.status) {
                case 'connected':
                    // App is authorized and user logged
                    onSuccess(response);
                    break;
                case 'not_authorized':
                    // User is logged into Facebook, but app is not authorized
                    fbLogin();
                    break;
                default:
                    // The user is not logged into Facebook
                    fbLogin();
            }
        });
    });

/**
 * Returns the tracking data to send on success or failure.
 * @param  {String} type
 * @param  {Object} params
 * @return {Object}
 * @private
 */
const getTrackingData = (type, params) => {
    if (!type) {
        ExceptionService.handle('error', {
            filename,
            message: 'getTrackingData() - type missing',
        });
    }
    return {
        access_type: TrackingService.getTrackingAccessType(type),
        ...params,
    };
};

/**
 * Function to be invoked when a login successfully happened
 * to send the tracking. We resolve the promise only after a certain
 * time to let the tracking go. (There's additional calls from the tracking
 * controller just after we identfied a new user (gtm trackers, etc.))
 * @param  {String} type
 * @param  {Object} params - additional params, optional
 * @return {Promise}
 */
const sendLoginSuccessTracking = (type, params = {}) => {
    const data = getTrackingData(type, params);
    return Tracking.sendAndWait('USER_LOGIN_SUCCESS', data);
};

/**
 * @param  {Object} params - additional params, optional
 * @return {Promise}
 */
const sendNonceSuccessTracking = (type, params = {}) => {
    const data = getTrackingData(type, params);
    return Tracking.sendAndWait('LOGIN_NONCE_SUCCESS', data);
};

/**
 * @param  {String} type
 * @param  {Object} params - additional params, optional
 * @return {Promise}
 */
const sendLoginErrorTracking = (type, params = {}) => {
    const data = getTrackingData(type, params);
    return Tracking.sendAndWait('USER_LOGIN_FAILED', data);
};

/**
 * @param  {String} type
 * @param  {Object} params - additional params, optional
 * @return {Promise}
 */
const sendNonceErrorTracking = (type, params = {}) => {
    const data = getTrackingData(type, params);
    return Tracking.sendAndWait('LOGIN_NONCE_ERROR', data);
};

/**
 * Returns a translated error message based on the error type
 * @param {String} type
 */
const getErrorMessage = (type) => {
    switch (type) {
        case 'INVALID_CREDENTIALS':
            return TranslationsService.getTranslation('LOGIN_UNRECOGNIZED');
        case 'CODE_ACTIVATED':
        case 'USER_ALREADY_IN_INSTITUTION':
            return TranslationsService.getTranslation('LOOKS_LIKE_YOURE_ALREADY_REGISTERED_PLEASE_USE');
        case 'CODE_NOT_FOUND':
        case 'CODE_ACTIVATION_ERROR':
            return TranslationsService.getTranslation('SORRY_YOUR_ARE_NOT_AUTHORISED_TO_USE_THIS_APP_PLEASE_CONTACT_X');
        default:
            return `${TranslationsService.getTranslation('ERROR')} - ${TranslationsService.getTranslation(
                'TRY_AGAIN'
            )}`;
    }
};

/**
 * @param {Boolean} twoFactorAuthenticationEnabled
 * @return {String}
 */
const getEmailMobileSwitcherInitialState = (twoFactorAuthenticationEnabled = false) => {
    const initialFormType = Utils.getParameter('type');
    const TYPE_EMAIL = 'email';
    const TYPE_MOBILE = 'mobile';

    if (initialFormType === 'phone' || twoFactorAuthenticationEnabled) {
        return TYPE_MOBILE;
    }

    return TYPE_EMAIL;
};

const getPlatformSource = () => {
    const mobileAuthParameter = QueryParameters.getMobileAuthParameter();
    const userComesFromMobile = MobileService.isAuthenticationOrRegistrationMobileFlow();
    const defaultPlatform = 'web';

    return userComesFromMobile ? mobileAuthParameter : defaultPlatform;
};

const AuthenticationService = {
    authUser,
    autoAuthUser,
    autoLoginUser,
    authFacebookUserOnBusuu,
    authGoogleUserOnBusuu,
    authAppleUserOnBusuu,
    authUserOnFacebook,
    sendLoginSuccessTracking,
    sendLoginErrorTracking,
    sendNonceSuccessTracking,
    sendNonceErrorTracking,
    getErrorMessage,
    getAuthNonce,
    getEmailMobileSwitcherInitialState,
    getPlatformSource,
};

export default AuthenticationService;
