import {Utils, ScriptLoader, ThirdPartyService} from '@busuu/legacy-core';
import PubSub from 'pubsub-js/src/pubsub.js';

/**
 * Facebook
 */
(function(root, doc, undefined) {

    /**
     * Private vars
     */
    const localeMapping = {
        en: 'en_GB',
        fr: 'fr_FR',
        de: 'de_DE',
        ar: 'ar_AR',
        pl: 'pl_PL',
        it: 'it_IT',
        zh: 'zh_CN',
        ja: 'ja_JP',
        pt: 'pt_BR',
        es: 'es_ES',
        ru: 'ru_RU',
        tr: 'tr_TR'
    };
    const PRODUCTION_APPID = 61455312096;
    const TEST_APPID = 281710769196326;

    const getAppId = () => {
        return Utils.isProduction() ? PRODUCTION_APPID : TEST_APPID;
    };

    /**
     * Init Facebook variables
     */
    root.fbAsyncInit = () => {
        FB.init({
            appId: getAppId(),
            status: true,
            cookie: true,
            xfbml: true,
            version: 'v18.0'
        });
        PubSub.publish('FACEBOOK_API_LOADED');
    };

    const isVendorDisabled = () => {
        return ThirdPartyService.isVendorDisabled();
    };

    /**
     * Returns Facebook locale from a given language
     */
    const getFacebookLocale = () => {
        var locale = localeMapping[document.documentElement.lang];
        return locale || localeMapping.en;
    };

    /**
     * Inject it! (delayed after load event)
     */
    const init = () => {
        if (!isVendorDisabled()) {
            ScriptLoader.add('//connect.facebook.net/' + getFacebookLocale() + '/sdk.js', 'facebook-jssdk');
        }
    };

    init();

    return {
        getFacebookLocale
    };

})(window, document);
