import {Utils} from '@busuu/legacy-core';

const SPINNER_CLASSES = 'main-spinner-a';

const showElement = (element) => {
    if (element) {
        Utils.removeClass(element, 'hidden');
    }
};

const hideElement = (element) => {
    if (element) {
        Utils.addClass(element, 'hidden');
    }
};

const toggleElement = (element, show) => {
    if (show) {
        showElement(element);
    } else {
        hideElement(element);
    }
};

const focusElement = (element, useTimeout = false) => {
    if (element) {
        if (useTimeout) {
            setTimeout(() => {
                element.focus();
            }, 1);
        } else {
            element.focus();
        }
    }
};

const requireElement = (element) => {
    if (element) {
        element.setAttribute('required', '');
    }
};

const unRequireElement = (element) => {
    if (element) {
        element.removeAttribute('required');
    }
};

const toggleRequireElement = (element, required) => {
    if (required) {
        requireElement(element);
    } else {
        unRequireElement(element);
    }
};

const disableElement = (element) => {
    if (element) {
        element.setAttribute('disabled', 'disabled');
    }
};

const enableElement = (element) => {
    if (element) {
        element.removeAttribute('disabled');
    }
};

const stopButtonLoading = (element) => {
    if (element) {
        enableElement(element);
        Utils.removeClass(element, SPINNER_CLASSES);
    }
};

const setButtonLoading = (element) => {
    if (element) {
        disableElement(element);
        Utils.addClass(element, SPINNER_CLASSES);
    }
};

const markCheckBox = (element, checked) => {
    if (element) {
        if (checked) {
            element.setAttribute('checked', 'checked');
        } else {
            element.removeAttribute('checked');
        }
    }
};

const toggleElementDisabled = (element, disabled) => {
    if (disabled) {
        disableElement(element);
    } else {
        enableElement(element);
    }
};

const FormUtils = {
    showElement,
    hideElement,
    toggleElement,
    focusElement,
    requireElement,
    unRequireElement,
    toggleRequireElement,
    setButtonLoading,
    stopButtonLoading,
    markCheckBox,
    disableElement,
    enableElement,
    toggleElementDisabled,
};

export default FormUtils;
