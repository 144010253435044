import {Utils} from '@busuu/legacy-core';

/**
 * Gets the width of a div with a specific id
 *
 * @param {string} id
 * @param {number} maxAttempts
 * @param {number} delay
 * @returns number
 */
const getWidthWithRetry = async (id, maxAttempts = 3, delay = 500) => {
    let attempts = 0;

    async function getWidth() {
        const width = Utils.getById(id).offsetWidth;

        if (width === 0 && attempts < maxAttempts) {
            attempts += 1;
            await new Promise((resolve) => setTimeout(resolve, delay)); // Wait for the delay
            return getWidth(); // Retry
        }
        return attempts < maxAttempts ? width : null;
    }

    return getWidth(); // Await the result of getWidth function
};

export {getWidthWithRetry};
