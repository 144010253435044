import {Utils} from '@busuu/legacy-core';

const AuthenticationGoogleConstant = {
    googleSigninButtonName: 'google-signin-button',
    clientId: '1060086548769-4emh20mnka3ckfrh14b9lad7h9ucu35j.apps.googleusercontent.com',
};

export const BUSUU_DOMAINS = {
    PRODUCTION: 'https://www.busuu.com',
    STAGING: 'https://staging.internal.busuu.com',
    LOCAL: 'https://local-hp.internal.busuu.com',
};

/**
 * Used for Apple auth. Required same domain.
 */
const getRedirectURL = () => {
    if (Utils.isLocalEnvironment()) return BUSUU_DOMAINS.LOCAL;
    if (Utils.isProduction()) return BUSUU_DOMAINS.PRODUCTION;
    return BUSUU_DOMAINS.STAGING;
};

const AuthenticationAppleConstant = {
    clientId: 'com.busuu.appleweb',
    scope: 'name email', // we ask for this info. Name and email will come only in the first call.
    redirectURI: getRedirectURL(), // Apple require this parameter
    state: 'b4a2422e1ceb8ee3032cce72b8f4a98dccf7122032e30ee3b1d7ce7a3243', // Arbitrary string. Will be in the response.
    usePopup: true, // We want open a pop up. Also this allows us to control the redirection
};

/**
 * list of locales for the button
 * https://developer.apple.com/documentation/sign_in_with_apple/ +
 * sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
 */
const localeMapping = {
    ar: 'ar_SA',
    de: 'de_DE',
    en: 'en_GB',
    'en-US': 'en_US',
    es: 'es_ES',
    fr: 'fr_FR',
    id: 'id_ID',
    it: 'it_IT',
    ja: 'ja_JP',
    ko: 'ko_KR',
    pl: 'pl_PL',
    pt: 'pt_BR',
    ru: 'ru_RU',
    tr: 'tr_TR',
    vi: 'vi_VI',
    zh: 'zh_CN',
};

/**
 * Returns locale from a given language
 */
const getLocale = () => {
    const locale = localeMapping[document.documentElement.lang];
    return locale || localeMapping.en;
};

export {AuthenticationGoogleConstant, AuthenticationAppleConstant, getLocale};
