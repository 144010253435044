import {Utils} from '@busuu/legacy-core';

/**
 * Function invoked right after authentication or registration.
 * This will returns a location redirection provided by the backend if present.
 * Likely a B2B flow:
 * https://busuucom.atlassian.net/wiki/spaces/FRON/pages/5238849622/Login+via+Web+from+Mobile+Front-end#The-B2B-Flow
 * @param  {Object} response - server response
 * @return {String || null}
 */
const getBackendRedirectionLocation = (serverResponse) => {
    if (!Utils.isObject(serverResponse)) {
        return null;
    }

    const getRedirectionLocation = (response) => {
        const activeRedirect = Boolean(response.status === 'redirect');
        const locationPresent = Boolean(response.data && response.data.location);
        if (!activeRedirect || !locationPresent) {
            return null;
        }
        const {location} = response.data;
        return location.length && Utils.isTypeOf(location, 'string') ? location : null;
    };

    return getRedirectionLocation(serverResponse) || null;
};

const PostAuthenticationService = {
    getBackendRedirectionLocation,
};

export default PostAuthenticationService;
